<template>
  <PSection do-participation-rule-section theme="white" id="participation-rules">
    <div class="context">
      <h4>참여 시 꼭 확인하세요.</h4>
      <div class="detail">
        <em>대회 일정 참고사항</em>
        <div class="group">
          <div class="stage">인플루언서 도네이션 매치</div>
          <ul>
            <li>조추첨식: 11월 26일(금) 19:00</li>
            <li>풀리그: 12월 3일(금) ~ 4일(토) 19:00</li>
          </ul>
          <div class="stage">아마추어 오픈 토너먼트</div>
          <ul>
            <li>참가 접수: 11월 29일(월) 11:00 ~ 12월 10일(금) 21:00 12일간</li>
            <li>
              <span>예선:</span>
              <p>
                N강~256강 - 12월 11일(토) 14:00 <br /> 128강~16강 - 12월 12일(일) 14:00
              </p>
            </li>
            <li>
              <span>본선:</span>
              <p>
                8강 - 12월 15일(수) 19:00<br /> 4강 - 12월 16일(목) 19:00<br /> 결승(오프라인) - 12월 18일(토) 19:00
              </p>
            </li>
          </ul>
        </div>
        <em>대회 방식 참고사항</em>
        <div class="group">
          <div class="stage">인플루언서 도네이션 매치</div>
          <ul>
            <li>4팀 싱글 라운드로빈 | 전 경기 단판</li>
            <li>동률 시 상대전적 비교 | 상대 전적 동일시 순위 결정전 진행</li>
            <li>진영: 코인토스</li>
          </ul>

          <div class="stage">아마추어 오픈 토너먼트</div>
          <ul>
            <li>예선전: 싱글 엘리미네이션(토너먼트) | 전 경기 단판</li>
            <li>본선 8강: 싱글 엘리미네이션(토너먼트) | 전 경기 단판</li>
            <li>본선 4강: 더블 엘리미네이션 | 전 경기 단판</li>
            <li>본선 결승: 싱글 엘리미네이션(토너먼트) | 5전 3선승제</li>
          </ul>
        </div>
        <em>예선 진행 시 참고사항</em>
        <div class="group">
          <ul>
            <li>경기 장소: 전 경기 온라인 대회 진행</li>
            <li>경기 로스터: 최대 7인(참가 선수 5인, 후보 선수 2인)</li>
            <li>체크인: 대회 시작 1시간 전부터 50분 간 진행</li>
            <li>대진표 공개: 체크인 마감 후 10분 내 대진표 공개</li>
            <li>매칭 기준: 레벨업 페이지 대진표 기능을 통한 랜덤 매칭</li>
            <li>경기 진영: 랜덤 배정으로 레벨업 페이지 내 대진표 상세에서 확인 가능</li>
            <li>경기 설정: 소환사의 협곡5:5 | 토너먼트 드래프트</li>
            <li>경기 진행: 토너먼트 API 코드 확인 후 진행</li>
            <li>중계: 비 방송</li>
          </ul>
        </div>
        <em>본선 진행 시 참고사항</em>
        <div class="group">
          <ul>
            <li>경기 장소: 8강&amp;4강 온라인 | 결승 오프라인(금천구 독산동 빅픽처인터렉티브 스튜디오)</li>
            <li>경기 로스터: 최대 7인(참가 선수 5인, 후보 선수 2인) | 예선 기준 최소 3인이상 등록 필수</li>
            <li>매칭 기준: 레벨업 페이지 대진표 기능을 통한 랜덤 매칭</li>
            <li>
              <span>경기 진영:</span>
              <p>
                8강 &amp; 4강 - 대진표 상 랜덤 표시<br /> 결승 - 4강 2승0패팀 진영선택, 2세트부터는 이전 세트 패배팀이 진영 선택
              </p>
            </li>
            <li>경기 설정: 소환사의 협곡5:5 | 토너먼트 드래프트</li>
            <li>중계: 전 경기 생방송</li>
          </ul>
        </div>
        <em>보상 및 혜택 주의사항</em>
        <div class="group">
          <ul>
            <li>상금: 제세공과금은 수상자가 부담</li>
            <li>인게임 보상: 스킨, 와드, 감정 표현 등의 인게임 보상은 레벨업지지에서 등록한 LoL 본인인증 완료 계정에 지급</li>
            <li>
              애장품 응모권: 실제 경기에 1회 이상 참가한 경우 인당 1개 지급<br> (부전승, 부전패, 기권 등 비정상적으로 경기 참여했다면 응모권 획득 불가)
            </li>
            <li>애장품 응모기간: 12월 11일(토) 경기 참가 시 ~ 12월 14일(화) 18:00 마감</li>
            <li>
              현물 보상: 배송지 확인이 필요한 현물 보상의 경우, 예선 종료 후 공식 디스코드를 통해 별도 공지 안내<br> (작성기간 초과, 배송지 오타 및 주소지 검색 불가 시 미지급 될 수 있음)
            </li>
          </ul>
        </div>
      </div>
    </div>
  </PSection>
</template>

<script>
export default {
  name: 'ParticipationRuleSection',
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[do-participation-rule-section] { .rel; .bgc(#1a1d22); .min-h(500); .c(white);
  .context { .rel; z-index: 2; .max-w(1280); .mh-c; .pt(60); .pb(120);
    > h4 { .fs(20, 20px); .o(.8);.min-w(196); .font-g; .tc;
      &:after { .cnt; width: calc(100% - 20px); .h(1.92); .bgc(white); .o(.1); .mt(20); .mb(18); .ml(10);}
    }
    .detail { .max-w(336); .tl; .mh-c; .font-spoqa;
      .stage { .mt(8);}
      > em {.o(.6); .fs(16, 32px); .block; .mt(16); .mb(6); .bold;}
      > .group,
      > ul { .pl(8); .fs(11, 21px);
        > div { .bold; .o(.6); .ml(-5);}
        > ul {
          li { .o(.4); list-style: '- ';
            > span { .fl; .mr(4); }
            > p { .crop; }
          }
        }
        .line-etc { .lh(16);}
        .next-line { .ml(-5);}
        > li { .regular; .o(.4); list-style: '- ';
          > ul { .pl(10);
            > li { .fs(11, 16px); list-style: decimal; }
          }
        }
      }
    }
    &:after {.cnt; width: calc(100% - 20px); .h(1.92); .bgc(white); .o(.1); .mt(24); .ml(10); }
  }
  @media (@tl-up) {
    .context { .pt(120); .pb(200);
      > h4 { .fs(32, 41.6px);
        &:after { width: calc(100% - 116px); .m(40, 0); .ml(58);}
      }
      .detail { .max-w(780);
        .stage { .mt(12);}
        > em { .fs(24, 32px); .mt(40); .mb(12);}
        > .group,
        > ul { .pl(12); .fs(16, 32px);
          .line-etc { .lh(24);
            &:nth-of-type(2) { .lh(32);
              &.tab3 { .lh(24);}
            }
            &:nth-of-type(3) { .lh(32);}
            &:nth-of-type(4) { .lh(32);}
            &:nth-of-type(5) { .lh(32);}
          }
          .next-line { .ib; .ml(0);}
          .tab {
            > br {
              &:nth-of-type(1) { .hide;}
            }
          }
          .tab1 { .ib;}
          .tab2 {
            > br {
              &:nth-of-type(2) { .hide;}
              &:nth-of-type(5) { .hide;}
            }
          }
          > div { .fs(16, 32px); .ml(5);}
          > ul {
            li { .fs(16, 24px); .ml(15);}
            .tab1 { .ib;}
          }
          > li {
            > ul {
              > li { .fs(16, 24px);
              }
            }
          }
        }
        &:after { .mt(40); .ml(58); width: calc(100% - 116px); }
        > .group { .pl(0); /* .c(rgba(255,255,255,.4)); */
          /* .stage{ .c(rgba(255,255,255,.6)); } */
        }
      }
    }
  }
  @media (@ds-up) {
    .context {
      > h4 { .w(100%); .min-w(1080);
        &:after { width: calc(100%); .m(40, 0); }
      }
      .detail { .max-w(873);}
      &:after { .mt(40); .ml(58); width: calc(100%); }
    }
  }
}

</style>
