<template>
  <div loldocup-intro>
    <KeyVisualSection />
    <PhaseRewardSection />
    <AmateurMatchSection />
    <InfluencerMatchSection />
    <CommercialMovieSection />
    <ParticipationRuleSection />
    <PSection class="quick-menu-holder">
      <QuickMenu v-prx:fixed
        v-if="quickMenu.menus.length"
        :button="quickMenu.button"
        :menus="quickMenu.menus"
        @button-click="discordConnect"
      />
    </PSection>
  </div>
</template>

<script>
import gtag from 'shared/mixins/gtag';
import KeyVisualSection from '@/views/components/landing/loldocup/KeyVisualSection.vue';
import QuickMenu from '@/views/components/landing/QuickMenu.vue';
import ParticipationRuleSection from '@/views/components/landing/loldocup/ParticipationRuleSection.vue';
import PhaseRewardSection from '@/views/components/landing/loldocup/PhaseRewardSection.vue';
import InfluencerMatchSection from '@/views/components/landing/loldocup/InfluencerMatchSection.vue';
import CommercialMovieSection from '@/views/components/landing/loldocup/CommercialMovieSection.vue';
import AmateurMatchSection from '@/views/components/landing/loldocup/AmateurMatchSection.vue';
import meta from '@/data/meta';

export default {
  name: 'LoldocupIntro',
  components: { AmateurMatchSection, CommercialMovieSection, InfluencerMatchSection, PhaseRewardSection, ParticipationRuleSection, QuickMenu, KeyVisualSection },
  mixins: [gtag],
  data() {
    return {
      quickMenu: {
        button: { img: '/img/pages/icon/discord.svg', text: '팀원 찾기' },
        menus: [
          { domId: 'main-visual', name: '대회 소개' },
          { domId: 'basic-reward', name: '대회 보상' },
          { domId: 'amateur-match', name: '대회 방식' },
          { domId: 'influencer-match', name: '인플루언서 매치' },
          { domId: 'official-video', name: '홍보 영상' },
          { domId: 'participation-rules', name: '참고 사항' },
        ],
      },

    };
  },
  computed: {
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
  },
  methods: {
    discordConnect() {
      this.trackEvent('landing_discord_btn', 'click', `${this.upRouteGameId}`);
      window.open('https://discord.gg/PkpEJxM98K');
    },
  },
  metaInfo() {
    return meta[this.$route.params.id][this.$route.name];
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[loldocup-intro] { .font-g;
  .quick-menu-holder { .fix; .z(10); .w(100%);}
  .header { .rel; .flex-center; .h(92); .tc; .no-repeat('@{do-intro}/bg_title_green.png'); .bg-c; .bg-s(auto, 100%);
    &.none { background-image: none; .h(auto); }
  }
  .title { .rel; .ib; .fs(32); .ls(-2px);
    span { .regular; }
    > .s { .block; .mb(10);
      .dot {.ib; .vam; .wh(52, 5);}
      .star {.wh(23, 22); .mh(12); .rotate; .ib; .vam;}
    }
    .l { .hide; }
  }
  .badge-info { .flex; .flex-ai; .font-spoqa; .fs(16);
    dt { .inline-flex; .flex-ai; .flex-jc; .min-w(60); .h(32); .medium; .c(#fff); .bgc(#999); .br(16);
      &.point { .bgc(#af1b2d); }
    }
    dd { .ml(8); .medium; .c(#555); }
  }
  .button-area { .tc; }
  .btn-goto { .ib; .wh(300, 56); .mh-c; .lh(56); .tc; .fs(22); .regular; .c(#fff;); .ls(-2); .bg('@{do-intro}/bg_btn_goto.svg');
    span { .rel; }
  }

  @media (@tl-up) {
    .header { .rel; .flex-center; .h(88); .tc; .no-repeat('@{do-intro}/bg_title_green.png'); .bg-c; .bg-s(auto, 100%);
      &.none { background-image: none; .h(auto); }
    }
    .title { .bold; .fs(44); .lh(40);
      span { .medium; }
      > .s { .hide; }
      > .l { .ib-list; .abs; .block; .t(50%); .t-yc;
        &.right {.r(-144);
          .star { .mr(10); }
        }
        &.left {.l(-144);
          .star { .ml(10); }
        }
        > .dot {.ib; .vam; .w(72);}
        > .star {.ib; .wh(32); .vam; .rotate;}
      }
    }
    .badge-info {
      dt {}
      dd { .ml(14); }
    }
    .button-area { .mt(40); }
    .btn-goto { .wh(480, 74); .fs(30); .lh(74); .medium; .bg('@{do-intro}/pc/bg_btn_goto.svg'); }
  }
  @media (@ds-up) {
    .badge-info {
      dt {}
      dd { .ml(20); }
    }
    .button-area { .mt(50); }
  }
  @keyframes elastic-snap-scale {
    from { .o(0); .t-s(0); }
    60% { .o(1); .t-s(1.2);}
    100% { .o(1); .t-s(1);}
  }
}
</style>
