<template>
  <PSection do-key-visual-section id="main-visual">
    <div class="holder">
      <figure class="key-logo">
        <LoldocupMainSymbol />
      </figure>
      <div class="header">
        <h2 class="title">모일수록 행복해지는 협곡 토너먼트</h2>
      </div>
      <div class="summary-info">
        <dl class="badge-info">
          <dt>예선</dt>
          <dd>12월 11일(토) ~ 12월 12일(일)</dd>
        </dl>
        <dl class="badge-info">
          <dt class="point">본선</dt>
          <dd>12월 15일(수) ~ 12월 18일(토)</dd>
        </dl>
      </div>
      <div class="button-area">
        <TwinkleButton theme="navy" @click="goApply">참가 신청 바로가기</TwinkleButton>
      </div>
    </div>

    <p class="btn-down">
      <button @click="toNextSection">Scroll Down
        <svg-angle />
      </button>
    </p>
    <div class="visual" :class="{'ani': false }">
      <div class="key-l"></div>
      <div class="key-r"></div>
    </div>
  </PSection>
</template>

<script>
import SvgAngle from '@shared/graphics/svg-angle.vue';
import gtag from 'shared/mixins/gtag';
import TwinkleButton from '@/views/components/loldocup/TwinkleButton.vue';
import LoldocupMainSymbol from '@/views/components/gameHome/loldocup/LoldocupMainSymbol.vue';

export default {
  name: 'KeyVisualSection',
  components: { LoldocupMainSymbol, TwinkleButton, SvgAngle },
  mixins: [gtag],
  computed: {
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
  },
  methods: {
    toNextSection() {
      const secondSection = this.$el.nextSibling;
      const targetScrollOffset = secondSection.offsetTop - (document.querySelector('header').clientHeight || 0);
      this.$scroll.scrollMove(targetScrollOffset, 1000);
    },
    goApply() {
      this.trackEvent(`landing_challenge_01`, 'click', `${this.upRouteGameId}`);
      this.$router.push('/b/lol?page=arena');
    },
  },
  mounted() {
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
@ease: cubic-bezier(0.165, 0.84, 0.44, 1);

[do-key-visual-section] { .rel; .h(100vh); .min-h(850);
  .intro-up(1, {
    .holder {
      .key-logo { animation-duration: .8s; animation-name: elastic-snap-scale;
        [loldocup-main-symbol] { animation-delay: 1.5s;.jello;}
      }
      .header, .summary-info, .button-area { .t-y(0); .o(1); }
    }
    .btn-down { .o(1); }
  });

  .holder { .abs; .lt(50%, 50%); .z(1); .t-xyc; .w(100%);
    .header { .h(102); .no-repeat('@{do-intro}/bg_title_purple_key.svg'); .mt(-40); .o(0); .t-y(30); .tr-to(.5s, .3s, .8s);}
    .title { .w(304); .mh-c; .c(#240d55); }
  }
  .key-logo { .wh(375); .mh-c; .block; .mt(-50);
    [loldocup-main-symbol] { .wh(100%); .mt(0);}
  }
  .title { .lh(32);
    &:before,
    &:after { .hide; }
  }
  .summary-info { .flex; .flex-ai; .mt(16); flex-direction: column; .o(0); .t-y(30); .tr-to(.5s, .3s, .9s);
    .badge-info {
      dd { .c(#fff); }
      & + .badge-info { .mt(8); }
    }
  }
  .button-area { .mt(40); .wh(300, 56); .mh-c; .o(0); .t-y(30); .tr-to(.5s, .3s, 1s);
    > button { .wh(100%); .fs(22, 56);}
  }

  .btn-down { animation: wr-bounce 1.5s infinite; .o(0); .abs; .z(1); .b(50); .tc; .w(100%); .t-y(30); .tr-to(.3s, .3s, 1.5s);
    > button { .c(#333); .fs(14); .ls(0); .ib;
      > svg { .wh(22, 12); .stroke(#333); .block; .mh-c; .mt(4);}
    }
  }
  .visual { .abs; .trbl; .z(0); .bgc(#d6c1f7); .cover; .bg-xy(50%, 0); .crop; .no-repeat('@{do-intro}/img_key_visual.jpg');
    .key-l,
    .key-r { .abs; .b(-1); .bg-y(100%); .z(1); transition+: transform .75s @ease; }
    .key-l { .r(calc(50% + 115px)); .wh(866, 495); .no-repeat('@{do-intro}/key_l.svg'); .t-x(160); }
    .key-r { .l(calc(50% + 115px)); .wh(881, 475); .no-repeat('@{do-intro}/key_r.svg'); .t-x(-160); }
  }

  @media (@tl-up) {
    .h(820); .min-h(auto);
    .intro-up(1, {
      .visual.ani {
        &:before,
        &:after {
          clip-path: circle(100% at 50% 50%);
          .o(1);
        }
      }
    });
    .holder {
      .header { .bg-s(100%, 71);
        .title { .w(auto); .fs(36); .lh(52); }
      }
      .key-logo { .wh(460); }
      .summary-info { .flex; .flex-jc; .mt(20); flex-direction: row;
        .badge-info + .badge-info { .mt(0); .ml(28); }
      }
      .button-area { .wh(480, 74);
        > button { .wh(100%); .fs(30, 100%); }
      }
    }
    .btn-down { .b(30);
      > button { .fs(16);}
    }
    .visual { .no-repeat('@{do-intro}/pc/img_key_visual_dark.jpg');
      .key-l,
      .key-r { .t-x(0); }
      &.ani { .no-repeat('@{do-intro}/pc/img_key_visual_dark_blur.jpg');
        &:before,
        &:after { .cnt; .abs; .trbl; clip-path: circle(0% at 50% 50%); .o(0); .cover; .bg-xy(50%, 0);
          .tr-o(1.2s, .6s);
          transition+: clip-path 2.6s .5s cubic-bezier(0.165, 0.84, 0.44, 1);
        }
        &:before { .no-repeat('@{do-intro}/pc/img_key_visual_color.jpg'); }
        &:after { .no-repeat('@{do-intro}/pc/img_key_visual_dark.jpg'); transition-delay: .8s; }
      }
    }
  }
  @media (@ds-up) {
    .h(916);
    .holder {
      .header { .mt(-70); .bg-s(100%, 80);
        .title { .w(auto); .fs(40); }
      }
      .key-logo { .t(40); .wh(520); .mt(-100);}
    }
    .btn-down { .b(60); }
  }
}
</style>
