<template>
  <PSection do-influencer-match-section id="influencer-match">
    <div class="holder">
      <div class="summary">
        <div class="header">
          <LoldocupStarTitle theme="white">인플루언서
            <span>도네이션 매치</span>
          </LoldocupStarTitle>
        </div>
        <h3 class="title-sub">기부금을 적립하기 위한 인플루언서들의 협곡 토너먼트</h3>
        <div class="summary-info">
          <dl class="badge-info">
            <dt>조추첨 생방송</dt>
            <dd>11월 26일(금) 19:00</dd>
          </dl>
          <dl class="badge-info">
            <dt class="point">대회 생방송</dt>
            <dd>12월 3일(금) ~ 12월 4일(토) 19:00</dd>
          </dl>
        </div>
      </div>

      <div class="team-list" v-if="isTeamList">
        <PItem tag="dl" :class="['team-item', team.type]" v-for="(team, i) in teams" :key="`team-${i}`">
          <dt>{{ team.teamName }}
            <span>TEAM</span>
          </dt>
          <dd>
            <ul>
              <li v-for="(user, j) in team.users" :key="`user-${j}`">
                <figure>
                  <img :src="`/img/pages/loldocup/intro/${user.img}`" :alt="user.name">
                </figure>
                <span>{{ user.name }}</span>
              </li>
            </ul>
          </dd>
        </PItem>
      </div>
      <PItem tag="div" class="team-list-message" v-que="{delay:0}" v-else>
        <p>“ 11월 29일(월) 공개됩니다. ”</p>
      </PItem>

      <PItem class="message">
        <div class="message-in">
          <div><b>총 상금 600만원</b>과 <b>기부금 500만원</b>이 걸린</div>
          <div><em>인플루언서 4팀</em>의 도네이션 매치를 생방송으로 확인하세요.</div>
        </div>
      </PItem>
    </div>
  </PSection>
</template>

<script>
import LoldocupStarTitle from '@/views/components/loldocup/LoldocupStarTitle.vue';
import que from '@/directives/que';

export default {
  name: 'InfluencerMatchSection',
  components: {
    LoldocupStarTitle,
  },
  directives: { que },
  data() {
    return {
      isTeamList: true,
      teams: [
        {
          teamName: '갱기도 미남',
          type: 'a',
users: [
            { isReader: true, img: 'img_team_1_1.png', name: '감독 고릴라' },
            { isReader: false, img: 'img_team_1_2.png', name: '해기' },
            { isReader: false, img: 'img_team_1_3.png', name: '남봉' },
            { isReader: false, img: 'img_team_1_4.png', name: '갱맘' },
            { isReader: false, img: 'img_team_1_5.png', name: '기도' },
            { isReader: false, img: 'img_team_1_6.png', name: '미미미누' },
          ],
        },
        {
          teamName: '막내가 말대꾸',
          type: 'b',
users: [
            { isReader: true, img: 'img_team_2_1.png', name: '감독 뉴클리어' },
            { isReader: false, img: 'img_team_2_2.png', name: '만득' },
            { isReader: false, img: 'img_team_2_3.png', name: '스나랑' },
            { isReader: false, img: 'img_team_2_4.png', name: '이야공' },
            { isReader: false, img: 'img_team_2_5.png', name: '눈꽃' },
            { isReader: false, img: 'img_team_2_6.png', name: '얏따' },
          ],
        },
        {
          teamName: '보여주나',
          type: 'c',
users: [
            { isReader: true, img: 'img_team_3_1.png', name: '감독 쿠로' },
            { isReader: false, img: 'img_team_3_2.png', name: '강소연' },
            { isReader: false, img: 'img_team_3_3.png', name: '삼식' },
            { isReader: false, img: 'img_team_3_4.png', name: '현솔' },
            { isReader: false, img: 'img_team_3_5.png', name: '백설양' },
            { isReader: false, img: 'img_team_3_6.png', name: '제리유진' },
          ],
        },
        {
          teamName: '십시일반',
          type: 'd',
users: [
            { isReader: true, img: 'img_team_4_1.png', name: '감독 큐베' },
            { isReader: false, img: 'img_team_4_2.png', name: '중력' },
            { isReader: false, img: 'img_team_4_3.png', name: '푸린' },
            { isReader: false, img: 'img_team_4_4.png', name: '피닉스박' },
            { isReader: false, img: 'img_team_4_5.png', name: '러너' },
            { isReader: false, img: 'img_team_4_6.png', name: '여푸' },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[do-influencer-match-section] { .bgc(#240d55); .font-g;
  .holder { .c(#999); .pt(66); .pb(56); .bgc(#1d0a44);
    .header { .bg('@{do-intro}/bg_title_purple_dark.png');
      h2 span { .medium; }
    }
  }
  .title-sub { .max-w(244); .mh-c; .mt(24); .p(8, 10); .tc; .c(#fbe2ff); .regular; .fs(18); .lh(26); .ls(-1); .-a(#a47dd4); }
  .summary-info { .flex; .w(335); .mh-c; .mt(28); flex-direction: column;
    .badge-info { .fs(15);
      dt { .w(98); }
      dd { .c(#999); .nowrap; }
    }
    .badge-info + .badge-info { .mt(8); }
  }
  .team-list { .m(48, 20, 0, 20); }
  .team-item { .max-w(335); .mh-c; .ani-base;
    .intro-up(1, {.ani-on;});
    dt { .flex-center; .h(74); .c(#fff); .bold; .fs(30); .tc; text-transform: uppercase;
      span { .rel; .t(5); .ml(3); .regular; .fs(16); }
    }
    dd { .rel; .h(355); .mt(4); .no-repeat('@{do-intro}/bg_team_content.svg'); .crop; }
    ul { .flex; .flex-wrap; .p(23, 0, 0, 16); }
    li { .w(96); .mr(8); .mb(12); .pl(1); .pt(1); .c(#fff); .bgc(#a47dd4); .-box;
      figure { .block; .wh(94, 120);.bgc(white); .crop;
        img { .wh(100%, auto;); }
      }
      span { .block; .max-w(96); .regular; .fs(14); .lh(28); .tc; .ellipsis; }
    }
    &.a {
      dt { .bg('@{do-intro}/bg_team_tit_a.svg'); }
      li { .bgc(#8149c5);
        &:first-child { .bgc(#4a138e); }
      }
    }
    &.b {
      dt { .bg('@{do-intro}/bg_team_tit_b.svg'); }
      li { .bgc(#929345);
        &:first-child { .bgc(#5b5c31); }
      }
    }
    &.c {
      dt { .bg('@{do-intro}/bg_team_tit_c.svg'); }
      li { .bgc(#c86f1c);
        &:first-child { .bgc(#8a5524); }
      }
    }
    &.d {
      dt { .bg('@{do-intro}/bg_team_tit_d.svg'); }
      li { .bgc(#1993d7);
        &:first-child { .bgc(#0b70a8); }
      }
    }
  }
  .team-item + .team-item { .mt(32); }
  .team-list-message { .rel; .wh(335, 335); .mh-c; .mt(48); .no-repeat('@{do-intro}/bg_team_message.jpg'); .bg-c; .cover; .ani-base;
    .intro-up(1, {.ani-on; });
    &:before { .cnt; .abs; .l(50%); .t(129); .z(1); .t-xy(-50%, 50); .wh(32); .no-repeat('@{do-intro}/bg_star_white.svg'); .bg-s(32, 32);
      transform: translate(-50%, 50px) rotate(0deg);
      transition: transform 1s .2s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    //&:after{ .cnt; .abs; .l(0); .r(0); .t(0); .b(0); .bgc(#3F2D65); .o(.4); }
    p { .rel; .pt(181); .z(1); .medium; .fs(24); .c(#fff); .ls(-2); .tc;
      clip-path: polygon(0% 0%, 0% 0%, 0% 0%);
      transition: clip-path 2s .5s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    &.que-done {
      &:before { transform: translate(-50%, 0) rotate(180deg); }
      p { clip-path: polygon(0% 0%, 200% 0%, 0% 200%); }
    }
  }
  .message { .w(335); .mh-c; .flex-center; .mt(48); .pv(28); .bgc(#240d55); .ani-base;
    .intro-up(1, {.ani-on;});
    .message-in { .rel; .max-w(259); .mh(20); .regular; .fs(22); .lh(30); .c(#4271c7); .tc; .ls(-1px);
      > div { .ph(11); .lh(32);
        &:last-child { .ph(0); }
      }
      &:before,
      &:after { .cnt; .abs; .t(0); .wh(16, 16); .bgc(#f8ccff);
        clip-path: path('M2 12.464H7.056V7.504H4.752C4.752 5.872 5.744 4.688 7.44 4.528V2C3.952 2.288 2 4.912 2 8.336V12.464ZM8.72 12.464H13.776V7.504H11.472C11.472 5.872 12.464 4.688 14.16 4.528V2C10.672 2.288 8.72 4.912 8.72 8.336V12.464Z');
      }
      &:before { .l(-26); }
      &:after { .r(-26); .t-r(180deg); }
    }
    b { .c(#f7fed1); font-weight: inherit;}
    em { .c(#f1cffc); }
  }

  @media (@tl-up) {
    .rel; .crop;
    .pv(80);
    .holder { .rel; .w(910); .mh-c; .p(60, 0); .bgc(transparent); visibility: visible !important;
      &:before { .cnt; .abs; .trbl; .z(0); .bgc(rgba(0, 0, 0, .2)); .t-s(1);
        transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
      }
      &.que-done:before { .t-s(1); }
    }
    .title-sub { .rel; .z(1); .max-w(618); .mt(20); .pv(9); .medium; .fs(24); .lh(30); }
    .summary-info { .rel; .z(1); .flex-center; .w(auto); .mt(20); flex-direction: row;
      .badge-info {
        dt { .w(108); .bgc(#555); }
      }
      .badge-info + .badge-info { .ml(28); .mt(0); }
    }

    .team-list { .w(854); .mt(60); .mh-c; .ph(0); }
    .team-item { .flex; .h(178); .max-w(100%);
      dt { flex-grow: 0; .block; .wh(106, 100%); .pt(49); .ph(15); .fs(24); .cover; .ls(-2px);
        span { .block; .mt(0); .regular; .fs(18); .ls(-0.01em);}
      }
      dd { flex-grow: 1; .h(100%); .mt(0); .ml(4); .pl(36); .pt(15); .bg('@{do-intro}/pc/bg_team_content_m.svg'); .bg-s(744, auto); }
      ul { .flex; .p(0); }
      li { .mr(16);
        span { .regular; }
        &:last-child { .mr(0); }
      }
      &.a dt { .no-repeat('@{do-intro}/pc/bg_team_tit_a_m.svg');
        span { .c(#c5aee1); }
      }
      &.b dt { .no-repeat('@{do-intro}/pc/bg_team_tit_b_m.svg');
        span { .c(#e8e9b4); }
      }
      &.c dt { .pt(63); .ph(0); .no-repeat('@{do-intro}/pc/bg_team_tit_c_m.svg');
        span { .c(#eaae77); }
      }
      &.d dt { .pt(63); .ph(0); .no-repeat('@{do-intro}/pc/bg_team_tit_d_m.svg');
        span { .c(#8ed6fd); }
      }
    }
    .team-item + .team-item { .mt(16); }
    .team-list-message { .wh(854, 354); .mt(60);
      &:before { .t(123); .wh(40); .bg-s(40, 40); }
      &:after {}
      p { .pt(181); .fs(44); transition-duration: 2.5s; }
    }
    .message { .w(854); .pv(18); .mt(32); .medium; .fs(26);
      .message-in { .rel; .tc; .max-w(626); .mh-c; .ph(10); .lh(38);
        &:before,
        &:after { .bgc(#4271c7); }
        &:before { .l(-36); }
        &:after { .r(-36); }
      }
      b { .c(#f7fed1); font-weight: inherit;}
      em { .c(#f1cffc); }
    }
  }
  @media (@ds-up) {
    .holder { .w(1200); }
    .title-sub { .mt(27); }
    .team-list { .w(1000); }
    .team-item { .flex; .h(200);
      dt { .w(158); .pt(68); .ph(40); }
      dd { .pl(72); .pt(26); .bg('@{do-intro}/pc/bg_team_content.svg'); .bg-s(838, auto);}
      li { .mr(20);
        //span { .medium; }
      }
      &.a dt { .no-repeat('@{do-intro}/pc/bg_team_tit_a.svg');
      }
      &.b dt { .no-repeat('@{do-intro}/pc/bg_team_tit_b.svg');
      }
      &.c dt { .pt(71); .no-repeat('@{do-intro}/pc/bg_team_tit_c.svg');
      }
      &.d dt { .pt(71); .no-repeat('@{do-intro}/pc/bg_team_tit_d.svg');
      }
    }
    .team-item + .team-item { .mt(20); }
    .team-list-message { .wh(966, 400); .mt(52);
      &:before { .t(146); }
      p { .pt(210); }
    }
    .message { .w(960); .medium; .fs(26);
      .message-in > div { .ph(0); }
    }
  }
}
</style>
