<template>
  <PSection do-commercial-movie-section id="official-video">
    <div class="holder">
      <header class="header">
        <LoldocupStarTitle theme="blue">홍보영상</LoldocupStarTitle>
      </header>

      <PItem class="video" v-que="{delay:0, ioRatio: 0.3, }">
        <figure>
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/CZMxMcOYFL8?loop=1&amp;mute=1&playlist=CZMxMcOYFL8"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            controls="0"
            allowfullscreen
          ></iframe>
        </figure>
      </PItem>

      <PItem class="button-area">
        <TwinkleButton theme="navy" @click="goApply">참가 신청 바로가기</TwinkleButton>
      </PItem>
    </div>
  </PSection>
</template>

<script>
import gtag from 'shared/mixins/gtag';
import TwinkleButton from '@/views/components/loldocup/TwinkleButton.vue';
import LoldocupStarTitle from '@/views/components/loldocup/LoldocupStarTitle.vue';
import que from '@/directives/que';

export default {
  name: 'CommercialMovieSection',
  mixins: [gtag],
  components: {
    LoldocupStarTitle,
    TwinkleButton,
  },
  directives: { que },
  computed: {
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
  },
  methods: {
    goApply() {
      this.trackEvent(`landing_challenge_02`, 'click', `${this.upRouteGameId}`);
      this.$router.push('/b/lol?page=arena');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[do-commercial-movie-section] { .bgc(white); .pt(66); .pb(56); .crop;
  .holder {
    .header { .bg('@{do-intro}/bg_title_blue.png'); }
  }
  .video { .rel; .max-w(375); .mh-c; .mt(20); .ani-base;
    .intro-up(1, { .ani-on; });
    &:before { .cnt; .abs; .trbl; .z(1); .bgc(#c1d6ff);}
    figure { .rel; .z(2); .wh(100%, 210);}
    .btn-play { .abs; .l(50%); .t(50%); .z(2); .t-xyc; .wh(33, 40); .fs(0); .c(transparent); .no-repeat('@{do-intro}/btn_play.svg'); .cover; }
  }
  .button-area { .mt(32); .wh(300, 56); .mh-c; .ani-base;
    .intro-up(1, { .ani-on; });
    > button { .wh(100%); .fs(22, 56);}
  }

  @media (@tl-up) {
    .pt(100); .pb(120);
    .holder .header { .rel; .z(1); }
    .video { .max-w(768); .mt(47); .o(1); visibility: visible !important;
      &:before { .trbl(40, -24, -20, -17); /*.l(-17); .t(40); .r(-24); .b(-20); */
        .t-s(3);
        transform-origin: bottom;
        transition: transform .75s cubic-bezier(0.165, 0.84, 0.44, 1);
      }
      figure { box-shadow: 0 3px 15px rgba(0, 0, 0, .4); .wh(100%, 430);}
      &.que-done {
        &:before { .t-s(1); }
        figure { box-shadow: none; }
      }
    }
    .button-area { .wh(480, 74); .mt(68) !important;
      > button { .wh(100%); .fs(30, 100%); }
    }
  }
  @media (@ds-up) {
    .video { .max-w(960);
      figure { .wh(960, 540); }
    }
    .button-area { .mt(100) !important; }
  }
}
</style>
