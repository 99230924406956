<template>
  <PSection do-phase-reward-section id="basic-reward">
    <div class="holder">
      <div class="step">
        <div class="header none">
          <LoldocupStarTitle>단계별 기본 보상</LoldocupStarTitle>
        </div>
        <ul>
          <PItem tag="li">
            <em>대회 참가 신청</em>
            <figure>
              <img src="/img/pages/loldocup/intro/img_tmp_1x1.png">
            </figure>
            <div class="msg">
              <p><b>1인 1,000원</b> 적립</p>
              <p>(기부처 : 사회복지공동모금회)</p>
            </div>
          </PItem>
          <PItem tag="li">
            <em>팀 구성 및 최초 경기 진행</em>
            <figure>
              <img src="/img/pages/loldocup/intro/img_tmp_1x1.png">
            </figure>
            <div class="msg">
              <p><b>온전한 수수께끼 세트 </b>획득</p>
              <p>(스킨, 와드, 감정표현)</p>
            </div>
          </PItem>
          <PItem tag="li">
            <em>경기 참가하여 승리 or 패배</em>
            <figure>
              <img src="/img/pages/loldocup/intro/img_tmp_1x1.png">
            </figure>
            <div class="msg">
              <p><b>애장품 응모권 </b>획득</p>
              <p><s>* 정상적인 경기 참가 시 지급됩니다.</s></p>
            </div>
          </PItem>
        </ul>
      </div>

      <div class="final">
        <div class="header none">
          <LoldocupStarTitle>상금 및 특별 보상</LoldocupStarTitle>
        </div>
        <div class="rank">
          <PItem tag="h3" class="title-sub">총 상금 2,000만원!</PItem>
          <ul class="rank-box">
            <PItem tag="li">
              <div class="rank-inner">
                <em>1위</em>
                <span>1,000만원</span>
              </div>
            </PItem>
            <PItem tag="li">
              <div class="rank-inner">
                <em>2위</em>
                <span>500만원</span>
              </div>
            </PItem>
            <PItem tag="li">
              <div class="rank-inner">
                <em>공동 3위</em>
                <span>각 150만원</span>
              </div>
            </PItem>
            <PItem tag="li">
              <div class="rank-inner">
                <em>공동 5위</em>
                <span>각 50만원</span>
              </div>
            </PItem>
          </ul>
        </div>

        <div class="special">
          <PItem tag="h3" class="title-sub">특별 보상</PItem>
          <ul class="rank-box">
            <PItem tag="li">
              <div class="rank-inner">
                <figure class="img-mobile">
                  <img src="/img/pages/loldocup/intro/trophy_m.png">
                </figure>
                <figure class="img-pc">
                  <img src="/img/pages/loldocup/intro/trophy_1024.png">
                </figure>
                <figure class="img-large">
                  <img src="/img/pages/loldocup/intro/trophy_1920.png">
                </figure>
                <em class="special-reward">본선 1위팀</em>
                <div class="msg">
                  <p>트로피 증정 및 기부자 대표 자격 부여</p>
                </div>
              </div>
            </PItem>
            <PItem tag="li">
              <div class="rank-inner">
                <figure class="img-mobile">
                  <img src="/img/pages/loldocup/intro/ahri_pack_m.png">
                </figure>
                <figure class="img-pc">
                  <img src="/img/pages/loldocup/intro/ahri_pack_1024.png">
                </figure>
                <figure class="img-large">
                  <img src="/img/pages/loldocup/intro/ahri_pack_1920.png">
                </figure>
                <em class="special-reward">예선 그룹 별 16강 <br class="hidden-tl-up" />(총 128팀)</em>
                <div class="msg">
                  <p>아리 10주년 패키지 증정</p>
                  <p><s>*팀 당 5세트씩 지급됩니다.</s></p>
                </div>
              </div>
            </PItem>
            <PItem tag="li">
              <div class="rank-inner">
                <figure class="img-mobile">
                  <img src="/img/pages/loldocup/intro/arcane_pad_m.png">
                </figure>
                <figure class="img-pc">
                  <img src="/img/pages/loldocup/intro/arcane_pad_1024.png">
                </figure>
                <figure class="img-large">
                  <img src="/img/pages/loldocup/intro/arcane_pad_1920.png">
                </figure>
                <em class="special-reward">체크인 선착순 500팀</em>
                <div class="msg">
                  <p>아케인 마우스 장패드 증정</p>
                  <p><s>*정상적인 경기 참가 시 지급됩니다.</s></p>
                </div>
              </div>
            </PItem>
          </ul>
        </div>
      </div>

    </div>
  </PSection>
</template>

<script>
import LoldocupStarTitle from '@/views/components/loldocup/LoldocupStarTitle.vue';

export default {
  name: 'PhaseRewardSection',
  components: {
    LoldocupStarTitle,
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[do-phase-reward-section] { .pb(56); .bgc(#f7e3fd);
  .holder { .max-w(335); .mh-c; }
  .header {
    [loldocup-star-title] { .fs(26, 32); .mb(32);}
  }
  .step { .pt(56);
    li { .rel; .wh(335, 292); .-box; .mt(40); .p(16, 16, 0, 16); .no-repeat('@{do-intro}/bg_step_box.svg'); .contain; .ani-base;
      .intro-up(1, {.ani-on});
      figure { .block; .p(58, 14, 14, 14); .bgc(#1d399c); .br-t(10);
        img { .wh(275, 118); .bg-s(275, 118); .bgc(rgba(0, 0, 0, .2)); }
      }
      em { .abs; .lt(32, 27); .flex; .flex-ai; .semi-bold; .fs(22, 38); .c(#fff); .ls(-2);
        &:before { .cnt; .ib; .wh(30, 33); .mr(10); .vam; .contain; }
      }
      .msg { .mt(12); .regular; .fs(20, 26); .c(#4271c7); .ls(-2); .tc;
        > p {}
        b { .c(#7e2581); .regular; }
        s { .fs(14, 24); .nowrap; }
      }
      &:before { .cnt; .abs; .lb(50%, -20); .t-xc; .z(1); .wh(32, 32); .ml(-16); .no-repeat('@{do-intro}/icon_circlr_arrow.svg'); .cover; transform: rotate(90deg) translateX(50%);}
      &:first-child { .mt(0);}
      &:last-child:before { .hide; }
      &:nth-child(1) {
        figure img { .bg('@{do-intro}/img_reward_1.png'); }
        em:before { .bg('@{do-intro}/icon_n_1.svg'); }
      }
      &:nth-child(2) {
        figure img { .bg('@{do-intro}/img_reward_2.png'); }
        em:before { .bg('@{do-intro}/icon_n_2.svg'); }
      }
      &:nth-child(3) {
        figure img { .bg('@{do-intro}/img_reward_3.png'); }
        em:before { .bg('@{do-intro}/icon_n_3.svg'); }
      }
    }
  }

  .title-sub { .h(62); .bold; .fs(26, 62); .tc; .ls(-1); .c(#fff); .contain; .ani-base;
    .intro-up(1, {.ani-on});
  }
  .rank-box { .flex;
    li { .tc;
      .rank-inner {
        .img-pc { .hide;}
        .img-large { .hide;}
        figure { .wh(303, 153);
          img { .wh(100%, 100%); }
        }
        em { .rel; .ib; .semi-bold; .ls(-2); .fs(24, 28);.c(#240d55); .ib;
          &:before, &:after { .cnt; .abs; .t(50%); .t-yc; .wh(32); .cover('@{do-intro}/icon_star.svg');}
          &:before { .l(-44); }
          &:after { .r(-44); }
        }
        > span { .rel; .block; .mt(23); .pt(17); .bold; .fs(20, 24); .ls(-1); .c(#4271c7);
          &:before { .cnt; .abs; .l(50%); .t(0); .wh(32, 1); .bgc(#4271c7); .t-xc; }
        }
        .msg {
          p { .medium; .fs(20, 26); .c(#8a1686); .ls(-2px); }
          span { .block; .fs(14, 24); .c(#4d71c1); }
        }
      }
    }
  }

  .final { .mt(40);
    [loldocup-star-title] { .mb(0);}
    .title-sub { .mt(32); .no-repeat('@{do-intro}/bg_title_sub_purple_dark.svg'); }
  }

  .rank {
    .rank-box { .flex-wrap;
      li { flex-grow: 0; .flex-center; .wh(109, 150); .contain('@{do-intro}/bg_rank_box.svg'); .tr-to(.5s, .5s); .ani-base;
        .intro-up(1, {.ani-on;});
        em { .semi-bold; .fs(18, 32); .ls(-2);}
        span { .mt(16); .pt(11); .fs(20, 24);
          &:before { .t(0); .h(2); }
        }
        &:first-child { .wh(335, 166); .mb(4); .contain('@{do-intro}/bg_rank_box_1.svg');
          em { .fs(26, 36);
            &:before,
            &:after { .wh(32); }
            &:before { .l(-42); }
            &:after { .r(-42); }
          }
          span { .mt(16); .pt(8); .lh(38); }
        }
        &:nth-of-type(n+2) {
          em {
            &:before, &:after { .wh(22); }
            &:before { .l(-24); }
            &:after { .r(-24); }
          }
        }
        &:nth-child(3) { .mh(4); .no-repeat('@{do-intro}/bg_rank_box_3.svg'); }
      }
    }
  }

  .special {
    .title-sub { .mt(32); .fs(24); .no-repeat('@{do-intro}/bg_title_sub_purple.svg'); }
    .rank-box { flex-direction: column;
      li { .h(295); .p(20, 16, 16, 16); .no-repeat('@{do-intro}/card back_sr_m_1.svg'); .bg-s(100%, 100%); .ani-base;
        .intro-up(1, {.ani-on;});
        figure { .wh(303, 153); .bgc(#041929); .br-t(8);
          img { .wh(100%, 100%); }
        }
        em { .mt(18); }
        .msg { .mh-c; .mt(12);
          p { .block; .fs(20, 20);
            s { .medium; .fs(14, 24); .c(#4271c7); .ls(-2); }
          }
        }
      }
      li:nth-of-type(2) { .mt(4); .h(325); .no-repeat('@{do-intro}/card back_sr_m_2.svg'); .bg-s(100%, 100%);
        figure { .bgc(#28052e);}
      }
      li:last-child { .mt(4); .h(295); .no-repeat('@{do-intro}/card back_sr_m_3.svg');
        figure {
          .bgc(#082d35);
          img { }
        }
      }
    }
  }

  @media (@tl-up) {
    .holder { .max-w(908); }
    .header [loldocup-star-title] { .fs(44); .mb(0); }
    .step { .pt(80);
      ul { .flex; .mt(40); }
      li { .wh(300, 238); .mt(0); .mr(4); .p(15, 16, 0, 16); .cover('@{do-intro}/pc/bg_step_box_m.svg');
        figure { .block; .p(48, 8, 8, 8);
          img { .wh(252, 104); .bg-s(252, 104); }
        }
        em { .lt(24, 23); .fs(18, 33);
          &:before { .wh(28, 33); .mr(12); .cover; }
        }
        .msg { .mt(10); .fs(16, 20);
          s { .fs(12); }
        }
        &:before { .l(auto); .b(auto); .r(-1); .t(50%); .wh(28, 28);
          transform: translate(50%, -50%);
        }
        &:nth-child(1) { .z(2);
          figure img { .bg('@{do-intro}/pc/img_reward_1_m.png'); }
        }
        &:nth-child(2) { .z(1);
          figure img { .bg('@{do-intro}/pc/img_reward_2_m.png'); }
        }
        &:nth-child(3) {
          figure img { .bg('@{do-intro}/pc/img_reward_3_m.png'); }
        }
      }
    }

    .title-sub { .h(68); .fs(36, 68);}
    .final { .mt(40); .pt(50);
      .header { .rel;
        &:before { .cnt; .abs; .l(0); .r(0); .t(-50); .h(1); .bgc(#dfc7fc); }
      }
      .title-sub { .mt(40); .no-repeat('@{do-intro}/pc/bg_title_sub_purple_dark_m.svg'); .cover; }
      .rank-box { flex-wrap: nowrap;
        li { flex-grow: 0; .flex-center; .wh(188, 200); .ml(4); .no-repeat('@{do-intro}/pc/bg_rank_box_m.svg');
          em { .fs(28, 36);
            &:before, &:after { .wh(24);}
          }
          span { .mt(22); .pt(20); .fs(32, 38);
            &:before { .t(0)}
          }
          &:first-child { .wh(332, 200); .mb(0); .ml(0); .no-repeat('@{do-intro}/pc/bg_rank_box_1_m.svg');
            > em { .fs(28, 36);
              &:before,
              &:after { .wh(36); }
              &:before { .l(-42); }
              &:after { .r(-42); }
            }
            span { .mt(22); .pt(20); .lh(38); }
          }
          &:nth-child(1) em {
            &:before,
            &:after { .wh(36); }
            &:before { .l(-55); }
            &:after { .r(-55); }
          }
          &:nth-child(2) em {
            &:before,
            &:after { .wh(36); }
            &:before { .l(-50);}
            &:after { .r(-50); }
          }
          &:nth-child(3) { .mh(0); .ml(4); .no-repeat('@{do-intro}/pc/bg_rank_box_m.svg');
            em {
              &:before,
              &:after { .wh(36); }
              &:before { .l(-38); }
              &:after { .r(-38); }
            }
          }
          &:nth-child(4) em {
            &:before,
            &:after { .wh(36); }
            &:before { .l(-38); }
            &:after { .r(-38); }
          }
        }
      }
    }

    .special {
      .title-sub { .mt(28); .fs(36); .no-repeat('@{do-intro}/pc/bg_title_sub_purple_m.svg'); }
      .rank-box { flex-direction: row;
        li { .flex-ai(flex-start); .wh(452, 334); .p(21, 16, 16, 16); .cover;
          figure { .wh(259, 162); .br-t(8); }
          em { .mt(21); .fs(18, 18); .ls(-2);}
          .msg { .w(100%); .mh-c; .mt(4);
            p { .lh(24); .fs(16, 16px); .ls(-2); .mt(11); .mb(4);
              > s { .fs(12, 12px); .ls(-2);}
              span { .mt(0); .pt(0); }
              + p { .mt(-4); }
            }
          }
          .rank-inner {
            .img-pc { .block;}
            .img-mobile { .hide;}
          }

          .special-reward {
            &:before,
            &:after { .wh(24) !important }
            &:nth-of-type(1) {
              &:before { .l(-30);}
              &:after { .r(-30);}
            }
          }
        }
        li:first-child { .wh(300, 290); .no-repeat('@{do-intro}/card back_sr_1024.svg');}
        li:nth-of-type(2) { .mt(0); .wh(300, 290); .no-repeat('@{do-intro}/card back_sr_1024.svg');}
        li:last-child { .wh(300, 290); .mt(0); .ml(4); .no-repeat('@{do-intro}/card back_sr_1024.svg'); }
      }
    }
  }
  @media (@ds-up) {
    .holder { .max-w(1260); }
    .step { .pt(98);
      li { .wh(416, 402); .mr(6); .p(32, 27, 0, 27); .bg('@{do-intro}/pc/bg_step_box.svg');
        figure { .block; .p(76, 18, 16, 18);
          img { .wh(326, 168); .bg-s(326, 168); }
        }
        em { .lt(45, 50); .fs(24, 50);
          &:before { .wh(42, 50); }
        }
        .msg { .mt(24); .fs(24, 30);
          s { .fs(16); .ls(-1); }
        }
        &:before { .wh(36, 36); }
        &:nth-child(1) figure img { .bg('@{do-intro}/pc/img_reward_1.png'); }
        &:nth-child(2) figure img { .bg('@{do-intro}/pc/img_reward_2.png'); }
        &:nth-child(3) figure img { .bg('@{do-intro}/pc/img_reward_3.png'); }
      }
    }

    .title-sub { .bold; .fs(36);}
    .final { .w(1140); .mh-c; .mt(60); .pt(60);
      .header:before { .l(-60); .r(-60); .t(-60); }
      .title-sub { .no-repeat('@{do-intro}/pc/bg_title_sub_purple_dark.svg'); }
      .rank-box {
        li { .w(238); .no-repeat('@{do-intro}/pc/bg_rank_box.svg');
          em { .bold; .fs(32); }
          span { .mt(23); .pt(16); }
          &:first-child { .wh(414, 200); .no-repeat('@{do-intro}/pc/bg_rank_box_1.svg');
            em { .fs(32); }
            span { .mt(23); .pt(16); }
          }
          &:nth-child(3) { .no-repeat('@{do-intro}/pc/bg_rank_box.svg'); }
        }
      }
    }

    .special {
      .title-sub { .mt(28); .fs(36); .no-repeat('@{do-intro}/pc/bg_title_sub_purple.svg'); }
      .rank-box { flex-direction: row;
        li { .flex-ai(flex-start); .wh(374, 402); .p(28, 46, 0, 46);
          figure { .wh(318, 242); }
          em { .mt(20); .fs(28, 36); }
          .msg { .mt(12);
            p { .medium; .fs(20, 20); .mt(8); .mb(6);
              s { .fs(16, 16);}
            }
          }
          .special-reward { .fs(24, 36px) !important;

          }
          .rank-inner {
            .img-pc { .hide;}
            .img-large { .block;}
            .img-mobile { .hide;}
          }
        }
        li:first-child { .wh(374, 402); .cover; .no-repeat('@{do-intro}/card back_sr.svg');}
        li:nth-of-type(2) { .wh(374, 402); .ml(8); .no-repeat('@{do-intro}/card back_sr.svg');}
        li:last-child { .wh(374, 402); .mt(0); .ml(8); .no-repeat('@{do-intro}/card back_sr.svg');}
      }
    }
  }
}
</style>
