<template>
  <PSection do-amateur-match-section id="amateur-match">
    <div class="holder">
      <div class="header">
        <LoldocupStarTitle theme="blue">아마추어
          <span>오픈토너먼트</span>
        </LoldocupStarTitle>
      </div>

      <div class="box">
        <PItem class="tournament-box">
          <div class="tournament-info">
            <h3>예선</h3>
            <div class="msg">
              전체 참가팀을 <b>8그룹</b>으로 분배<br /> 소환사의 협곡 <b>그룹별 토너먼트 진행</b> (Bo1)
            </div>
            <div class="date-info">
              <div class="date-info__holder">
                <dl class="badge-info">
                  <dt>1차</dt>
                  <dd>12월 11일(토) 14:00</dd>
                </dl>
                <dl class="badge-info">
                  <dt>2차</dt>
                  <dd>12월 12일(일) 14:00</dd>
                </dl>
              </div>
            </div>
          </div>

          <div class="tournament-info">
            <h3>본선</h3>
            <div class="msg">
              예선 각 <b>그룹별 1위팀 선발</b>,<br /> 총 <b>8팀 본선</b> 진행
            </div>
            <div class="date-info">
              <div class="date-info__holder">
                <dl class="badge-info">
                  <dt>8강</dt>
                  <dd>12월 15일(수) 19:00</dd>
                </dl>
                <dl class="badge-info">
                  <dt>4강</dt>
                  <dd>12월 16일(목) 19:00</dd>
                </dl>
                <dl class="badge-info">
                  <dt class="point">결승</dt>
                  <dd>12월 18일(토) 19:00</dd>
                </dl>
              </div>
            </div>
          </div>
        </PItem>

        <PItem class="message">
          <p>본선부터 시작되는 <b>생방송</b>과 <b>애장품 추첨식</b>도 놓치지 마세요.</p>
        </PItem>
      </div>
      <GiftBoxSymbols />
    </div>
  </PSection>
</template>

<script>
import GiftBoxSymbols from '@/views/components/gameHome/loldocup/GiftBoxSymbols.vue';
import LoldocupStarTitle from '@/views/components/loldocup/LoldocupStarTitle.vue';

export default {
  name: 'AmateurMatchSection',
  components: {
    LoldocupStarTitle,
    GiftBoxSymbols,
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[do-amateur-match-section] { .pt(51); .bgc(#f7e3fd);
  .holder {
    .header { .bg('@{do-intro}/bg_title_purple.svg'); }
  }
  .box { .rel; .w(335); .mh-c; .mt(50); .p(0, 16, 0, 16); .bgc(#fff);
    &:before { .cnt; .abs; .lt(0, -18); .w(100%); .h(18); .bg('@{do-home}/white_seal_top.svg'); .bg-s(370, auto); }
  }
  .tournament-box { .ani-base;
    .intro-up(1, { .ani-on; });
  }
  .tournament-info { .rel; .p(100, 16, 32, 16); .bgc(#f8f1f8);
    > h3 { .abs; .l(0); .t(24); .min-w(156); .lh(44); .p(0, 64, 0, 23); .regular; .fs(24); .c(#fff); .bgc(#8a1686);
      //clip-path: polygon(100% 0, 90% 50%, 100% 100%, 0% 100%, 0% 0%);
      &:after { .cnt; .abs; .l(100%); .t(0); .b(0); .wh(15, 44); .bgc(#8a1686); .t-x(-1px);
        clip-path: polygon(100% 0, 0% 50%, 100% 100%, 0 100%, 0 0);
      }
    }
    > .msg { .regular; .fs(22); .lh(32); .c(#4271c7); .ls(-2px);
      p { .ib; }
      b { .c(#7e2581); font-weight: inherit; }
    }
    &:last-child { .mt(16);
      > h3 { .bgc(#ba2b1c);
        &:after { background-color: inherit; }
      }
    }
  }
  .date-info { .flex-center; .mt(32); .min-h(136); .pv(32); .-t(#4271c7); .-b(#4271c7);
    &__holder {}
    .badge-info {
      dt { .bgc(#555); }
      dd { .fs(20); }
    }
    .badge-info + .badge-info { .mt(8); }
  }
  .message { .flex-center; .ls(-1px); .mh(-16); .mt(24); .pv(28); .c(#f8ccff); .bgc(#240d55); /* .h(115); */ .ani-base;
    .intro-up(1, { .ani-on; });
    p { .rel; .regular; .tc; .w(254); .mh-c; .fs(22); .lh(30);
      &:before,
      &:after { .cnt; .abs; .t(0); .wh(16, 16); .bgc(#f1cffc);
        clip-path: path('M2 12.464H7.056V7.504H4.752C4.752 5.872 5.744 4.688 7.44 4.528V2C3.952 2.288 2 4.912 2 8.336V12.464ZM8.72 12.464H13.776V7.504H11.472C11.472 5.872 12.464 4.688 14.16 4.528V2C10.672 2.288 8.72 4.912 8.72 8.336V12.464Z');
      }
      &:before { .l(-28); }
      &:after { .r(-28); .t-r(180deg); }
    }
    b { font-weight: inherit; .c(#f7fed1); }
  }
  [gift-box-symbols] {
    .l { .t-xy(-17, 26); }
    .r { .t-xy(42, 26); }
  }
  @media (@tl-up) {
    .holder { .ph(0); }
    .box { .w(788); .mt(105); .p(0, 24, 80, 24);
      &:before { .lt(0, -65); .w(100%); .h(65); .bg('@{do-home}/pc/white_seal_top.svg'); .cover; }
    }
    .tournament-box { .flex; }
    .tournament-info { .rel; .p(74, 16, 25, 16); .w(calc(50% - 10px));
      > h3 { .t(20); .min-w(157); .lh(44); .p(0, 23, 0, 23); .bold; .fs(24);
        //clip-path: polygon(100% 0, 90% 50%, 100% 100%, 0% 100%, 0% 0%);
        &:after { .wh(15, 44); }
      }
      > .msg { .regular; .fs(20); .lh(30); .min-h(90);
        p { .block; }
      }
      &:last-child { .ml(20); .mt(0); }
    }
    .date-info { .mt(17); .h(148); .mh(8); .p;
      .badge-info {
        dt { .fs(10); .min-w(40); .h(22); }
        dd { .fs(14); }
      }
      .badge-info + .badge-info { .mt(8); }
    }
    .message { .mt(50); .mh(-24); .pv(25);
      p { .w(auto); .fs(24); .lh(38);
        &:before,
        &:after { .cnt; .abs; .t(0); .wh(16, 16); .bgc(#f1cffc);
          clip-path: path('M2 12.464H7.056V7.504H4.752C4.752 5.872 5.744 4.688 7.44 4.528V2C3.952 2.288 2 4.912 2 8.336V12.464ZM8.72 12.464H13.776V7.504H11.472C11.472 5.872 12.464 4.688 14.16 4.528V2C10.672 2.288 8.72 4.912 8.72 8.336V12.464Z');
        }
      }
    }
    [gift-box-symbols] {
      .l { .l(-116); .t-xy(0, 0); }
      .r { .r(-116); .t-xy(0, 0); }
    }
  }
  @media (@ds-up) {
    .box { .w(1200); .mt(148); .p(0, 52, 100, 52);
      &:before { .lt(0, -100); .w(100%); .h(100); .bg-s(auto, 100); }
    }
    .tournament-info { .p(110, 40, 52, 40); .w(calc(50% - 16px));
      > h3 { .t(36); .min-w(162); .lh(50); .p(0, 32, 0, 40); .fs(32);
        &:after { .wh(15, 50); }
      }
      > .msg { .medium; .fs(26); .lh(36); .min-h(auto);
        p { .ib; }
      }
      &:last-child { .ml(32); }
    }
    .date-info { .mt(20); .h(176); .mh(0); .p;
      .badge-info {
        dt { .fs(16); .min-w(60); .h(32); }
        dd { .fs(20); }
      }
      .badge-info + .badge-info { .mt(12); }
    }
    .message { .mt(40); .mh(-52); .pv(38);
      p { .w(auto); .medium; .fs(26); .lh(38); }
    }
    [gift-box-symbols] {
      .l { .l(-270); }
      .r { .r(-270); }
    }
  }
}
</style>
